<template>
  <div class="bookDetils">
    <header>
      <!-- <img class="header-bgimg" src="../../assets/imgs/nav-bg.png" alt=""> -->
      <div class="header-title">
        <!-- <img class="header-logo" src="../../assets/imgs/LOGO_1024x1024.png" alt=""> -->
        <p>NOVEL</p>
      </div>
      <div @click="goHome" class="home">Home</div>
    </header>
    <article>
      <div class="book">
        <img :src="list.cover" alt="" class="book_img">
        <ul>
          <li>{{list.name}}
          </li>
          <li>Author:{{list.author}}</li>
          <li><img src="../../assets/imgs/see-yellow.png" alt=""> {{list.total_nums}} readers</li>
        </ul>
        <!-- <div class="content-right">
          <div class="qrcode">
            <img src="../../assets/imgs/downIos.png" alt="">
            <div class="qrcode-title">Download APP IOS</div>
          </div>
          <div class="qrcode">
            <img src="../../assets/imgs/downAndroid.png" alt="">
            <div class="qrcode-title">Download APP Android</div>
          </div>
        </div> -->
      </div>
      <div class="introduction">
        <h3>Introduction :</h3>
        <p>{{list.description}}</p>
      </div>
    </article>
    <!-- <div class="poster">
      <ul>
        <li class="poster-write">
          <p>Scan code download easy reading</p>
          <p>Whenever and wherever you want to read</p>
          <p>Simple interface, easy to operate</p>
          <div class="poster-right">
            <div class="qrcode1">
              <img src="../../assets/imgs/downIos.png" alt="">
              <div class="qrcode1-title">Download APP IOS</div>
            </div>
            <div class="qrcode1">
              <img src="../../assets/imgs/downAndroid.png" alt="">
              <div class="qrcode1-title">Download APP Android</div>
            </div>
          </div>
        </li>
        <li class="poster-img"><img src="../../assets/imgs/poster3.png" alt=""></li>
      </ul>
      <ul>
        <li class="poster-img"><img src="../../assets/imgs/poster2.png" alt=""></li>
        <li class="poster-write">
          <p>The works are rich in content and diverse in style</p>
          <p>Whenever and wherever you want to read</p>
          <div class="poster-right">
            <div class="qrcode1">
              <img src="../../assets/imgs/downIos.png" alt="">
              <div class="qrcode1-title">Download APP IOS</div>
            </div>
            <div class="qrcode1">
              <img src="../../assets/imgs/downAndroid.png" alt="">
              <div class="qrcode1-title">Download APP Android</div>
            </div>
          </div>
        </li>

      </ul>
      <ul>
        <li class="poster-write">
          <p>The author and reader interact intimately</p>
          <p>Leave comments, vote and reward, and happy interaction with the author</p>
          <div class="poster-right">
            <div class="qrcode1">
              <img src="../../assets/imgs/downIos.png" alt="">
              <div class="qrcode1-title">Download APP IOS</div>
            </div>
            <div class="qrcode1">
              <img src="../../assets/imgs/downAndroid.png" alt="">
              <div class="qrcode1-title">Download APP Android</div>
            </div>
          </div>
        </li>
        <li class="poster-img"><img src="../../assets/imgs/poster1.png" alt=""></li>
      </ul> -->

    <!-- </div> -->
    <div class="mayAlso">
      <h1>We'll meet you at NOVEL </h1>
    </div>
  </div>
</template>
<script>
import booklist from './bookList.json'
export default {
  data () {
    return {
      list: ''
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created () {
    const bookId = this.$route.query.book_id
    this.getList(bookId)
  },
  methods: {
    getList (val) {
      const res = booklist[0].booklist
      // console.log(res)
      res.forEach((e) => {
        if (e.book_id === val) {
          this.list = e
        }
      })
    },
    goHome () {
      this.$router.push({ path: '/' })
    }

  }
}
</script>
<style  lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

header {
  background: linear-gradient(30deg, #2e2149 0%, #0b0517 100%);
  height: 100px;
  .header-bgimg {
    width: 100%;
    height: 100px;
  }
  .header-title {
    position: absolute;
    top: 20px;
    left: 160px;
    display: flex;
    .header-logo {
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
    p {
      margin-left: 20px;
      font-size: 28px;
      font-weight: 600px;
      color: #fff;
      line-height: 60px;
    }
  }
  .home {
    font-size: 30px;
    position: absolute;
    top: 30px;
    right: 160px;
    color: #fff;
  }
}
article {
  margin: 50px 240px;

  .book {
    width: 100%;
    height: 271px;
    display: flex;
    .book_img {
      width: 208px;
      height: 271px;
      border-radius: 10px;
      flex: 1;
    }
    ul {
      margin: 0 50px;
      flex: 5;
      height: 271px;
      display: flex;
      flex-direction: column;
      li {
        margin-top: 20px;
      }
      li:nth-of-type(1) {
        font-size: 40px;
        font-weight: 800px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        p {
          font-size: 24px;
          color: #999;
          font-weight: 400px;
        }
      }
      li:nth-of-type(2) {
        font-size: 24px;
      }
      li:nth-of-type(3) {
        font-size: 24px;
      }
    }
  }
  .introduction {
    margin-top: 30px;
    h3 {
      font-size: 30px;
    }
    p {
      margin-top: 30px;
      font-size: 24px;
    }
  }
}
.poster {
  background: linear-gradient(30deg, #dc2bac 0%, #7556fb 100%);
  ul {
    padding-left: 200px;
    height: 800px;
    display: flex;

    li {
      margin-top: 80px;
    }
    .poster-write {
      color: #fff;
      width: 700px;
      margin-top: 100px;
      line-height: 50px;
      p {
        margin-top: 40px;
        font-size: 40px;
      }
      p:nth-of-type(1) {
        font-size: 50px;
      }
    }
    .poster-img {
      img {
        width: 300px;
        height: 649px;
        border-radius: 10px;
      }
    }
    li:nth-of-type(2) {
      margin-left: 50px;
    }
  }
}
.content-right {
  flex: 1;
  display: flex;
}
.qrcode {
  margin-left: 40px;
  text-align: center;
}
.qrcode img {
  width: 140px;
  height: 140px;
  border-radius: 15px;
}
.qrcode-title {
  margin-top: 25px;
  text-align: center;
  width: 168px;
  height: 33px;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
}
.poster-right {
  margin-top: 40px;
  display: flex;
}
.qrcode1 {
  margin-left: 100px;
  text-align: center;
}
.qrcode1 img {
  width: 160px;
  height: 160px;
  border-radius: 15px;
}
.qrcode1-title {
  margin-top: 25px;
  text-align: center;
  width: 168px;
  height: 33px;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
}
.mayAlso {
  h1 {
    margin: 63px;
    font-size: 48px;
    font-weight: 500;
    text-align: center;
    color: #07061f;
    line-height: 48px;
  }
}
</style>
