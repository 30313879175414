<template>
  <div class="join-us">
    <img class="banner" src="../../assets/imgs/joinus.png" alt="">
    <div class="content">
      <div class="title">开放职位</div>
      <div class="subtitle">Job Opportunities</div>
      <div class="job-box">
        <div class="job" v-for="item in list" :key="item">{{item}}</div>
      </div>
      <div class="title">简历投递</div>
      <div class="subtitle">Join Us</div>
      <div class="mail">shenranzp@163.com</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        '财经作者',
        '新媒体运营',
        '短视频策划',
        '短视频主编',
        '运营实习生',
        '作者实习生'
      ]
    }
  },
  components: {
  }
}
</script>

<style scoped>
/* .banner{
  height: 468px;
  width: 100%;
  object-fit: cover;
} */
.content{
  margin-top: -163px;
  padding: 0 135px 40px;
  background: #000;
}
.title{
  /* display: inline-block; */
  /* width: 230px; */
  text-align:center;
  height: 64px;
  font-size: 56px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  line-height: 64px;
}
.subtitle{
  /* display: inline-block; */
  /* width: 300px; */
  text-align: center;
  height: 31px;
  font-size: 24px;
  font-family: Futura-Book, Futura;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  line-height: 31px;
}
.mail{
  margin-top: 50px;
  height: 56px;
  font-size: 40px;
  text-align: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 56px;
}
.job-box{
  margin:60px auto 113px;
}
.job::before{
  content: '';
  display: block;
  width: 4px;
  height: 36px;
  background: #F52727;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.job{
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
  width: 25%;
  text-indent: 11px;
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  line-height: 50px;
}
</style>
